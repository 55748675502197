<template>
  <div class="container">
    <h1>Categoriëen</h1>
    <p>Kies een categorie in het onderstaande overzicht</p>
    <hr />
    <categories-category
      v-for="category in parentCategories"
      :key="category.CategoryID"
      :category="category"
      :categories="categories"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CategoriesCategory from '@/views/Categories/CategoriesCategory'

export default {
  name: 'Categories',
  components: { CategoriesCategory },
  computed: {
    ...mapGetters('categories', {
      categories: 'getCategories'
    }),
    parentCategories() {
      return this.categories.filter(
        category => category.ParentCategoryID === null
      )
    }
  },
  created() {
    this.$store.dispatch('categories/fetchCategories')
  }
}
</script>

<style scoped></style>
